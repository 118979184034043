// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qBZ3oMW1{background:var(--color-bg);border:1px solid transparent;border-radius:var(--border-radius);box-shadow:0 .25rem 3rem rgba(var(--color-shadow-rgb),.12);display:flex;flex-direction:column;padding:var(--spacing-md);transition:.1s linear;transition-property:border}@media(hover:hover){.qBZ3oMW1:hover{border-color:var(--color-gray-3)}}@media(hover:none){.qBZ3oMW1:active{border-color:var(--color-gray-3)}}.qBZ3oMW1:not(:last-child){margin-bottom:var(--spacing-sm)}@media(max-width:667.98px){.qBZ3oMW1{padding:calc(var(--spacing-default)*5)}}.Hd\\+UnpQS{justify-content:space-between;margin-bottom:var(--spacing-s)}.Hd\\+UnpQS,.ipX16wg4{align-items:center;display:flex}.ipX16wg4{overflow:hidden}.dF2o1o\\+3{align-items:center;display:flex;margin-left:var(--spacing-md)}.dF2o1o\\+3>:not(:last-child){margin-right:var(--spacing-sm)}@media(max-width:667.98px){.dF2o1o\\+3>:not(:last-child){margin-right:var(--spacing-xs)}}.JwvUasXD{margin-right:var(--spacing-lg)}@media(max-width:667.98px){.JwvUasXD{margin-right:var(--spacing-sm)}}.\\+OsJKqLA{color:var(--color-primary-on-bg);font-size:1rem;font-weight:500;line-height:1.6;margin-right:var(--spacing-md)}@media(max-width:667.98px){.\\+OsJKqLA{font-size:.69rem;line-height:2.3;margin-right:var(--spacing-sm)}}.i9Gffxv9,.\\+OsJKqLA{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.JwvUasXD,.i9Gffxv9{color:var(--color-gray-4);font-size:.81rem;font-weight:500;line-height:1.39}@media(max-width:667.98px){.JwvUasXD,.i9Gffxv9{font-size:.69rem;line-height:1.63}}.Jt7jaBzS{--icon-size:1.5rem}.G-M7YBjg{color:var(--color-bg-invert);font-size:1.5rem;font-weight:700;line-height:1.46;word-wrap:break-word}@media(max-width:667.98px){.G-M7YBjg{font-size:1rem;font-weight:600;letter-spacing:.15px}}.dQN5WIQz.qBZ3oMW1{padding:var(--spacing-sm)}.dQN5WIQz .G-M7YBjg{font-size:.88rem;font-weight:600;line-height:1.57}.dQN5WIQz .\\+OsJKqLA{font-size:.81rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "dQN5WIQz",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "qBZ3oMW1",
	"head": "Hd+UnpQS",
	"headMeta": "ipX16wg4",
	"headAside": "dF2o1o+3",
	"time": "JwvUasXD",
	"trackName": "+OsJKqLA",
	"trackDesc": "i9Gffxv9",
	"btn": "Jt7jaBzS",
	"title": "G-M7YBjg"
};
module.exports = ___CSS_LOADER_EXPORT___;
